import React, { useEffect, useState } from "react";
import axios from "axios";

const FetchImages = ({ fieldNameId, closeAdminDashboard }) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchImages = async () => {
      setIsLoading(true); // Start loading

      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/images/field/${fieldNameId}`
        );
        const fetchedImages = response.data.map((img) => {
          const [id, path] = img.split(",");
          // Replace "gs://" with "https://storage.cloud.google.com/"
          const imagePath = path.replace(
            "gs://",
            "https://storage.cloud.google.com/"
          );
          return { id, path: imagePath, status: "Fetching..." };
        });

        await fetchSubmissionStatus(fetchedImages);
      } catch (error) {
        console.error("Error fetching images:", error);
        alert("Failed to fetch images");
      } finally {
        setIsLoading(false); // End loading
      }
    };

    const fetchSubmissionStatus = async (images) => {
      const updatedImages = await Promise.all(
        images.map(async (image) => {
          try {
            const response = await axios.get(
              `${API_BASE_URL}/api/images/get?fieldNameId=${fieldNameId}`
            );
            const statusData = response.data;
            if (
              statusData.verificationApproved === true &&
              statusData.verificationPending === false
            ) {
              image.status = "Approved";
            } else if (
              statusData.verificationApproved === false &&
              statusData.verificationPending === false
            ) {
              image.status = "Rejected";
            } else {
              image.status = "Pending";
            }
            return image;
          } catch (error) {
            // Handle cases where the API returns a 404 or other errors
            if (error.response && error.response.status === 404) {
              console.log(`No status found for imageId: ${image.id}`);
              image.status = "No Data"; // or any other default status
            } else {
              console.error("Error fetching status:", error);
              image.status = "Error"; // Indicate an error fetching the status
            }
            return image;
          }
        })
      );
      setImages(updatedImages);
    };

    if (fieldNameId) {
      fetchImages();
    }
  }, [fieldNameId]);

  const handleDeleteImage = async (imageId, fieldNameId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this image?"
    );
    if (!confirmDelete) {
      return; // If the user cancels, exit the function
    }
    try {
      // Make both API calls in parallel
      const [deleteImageResponse, deleteFieldResponse] = await Promise.all([
        axios.delete(`${API_BASE_URL}/api/images/delete/${imageId}`),
        axios.delete(
          `${API_BASE_URL}/api/images/delete?fieldNameId=${fieldNameId}`
        ),
      ]);
      if (
        deleteImageResponse.status === 200 &&
        deleteFieldResponse.status === 200
      ) {
        alert("Image deleted successfully");
        // Filter out the deleted image from the state
        const updatedImages = images.filter((image) => image.id !== imageId);
        setImages(updatedImages);
      } else {
        alert("Failed to delete image");
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image: " + error.message);
    }
  };

  const handleSubmit = async (imageId, action) => {
    const payload = {
      submissionSuccess: true,
      verificationApproved: action === "approve",
      verificationPending: false,
      fieldNameId: fieldNameId,
    };

    try {
      const response = await axios.put(
        `${API_BASE_URL}/api/images/update`,
        payload,
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.status === 200) {
        alert(
          `Image ${action === "approve" ? "approved" : "rejected"} successfully`
        );
        // Optionally update status immediately in UI without refetching all statuses
        const updatedImages = images.map((image) =>
          image.id === imageId
            ? {
                ...image,
                status: action === "approve" ? "Approved" : "Rejected",
              }
            : image
        );
        setImages(updatedImages);
      } else {
        alert("Submission failed");
      }
    } catch (error) {
      console.error("Error submitting action:", error);
      alert("Submission failed: " + error.message);
    }
  };

  return (
    <div>
      <div className="popup-content">
        <span className="close-button" onClick={closeAdminDashboard}>
          &times;
        </span>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Image</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {images.map((image, index) => (
                <tr key={index}>
                  <td>
                    <a
                      href={image.path}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  </td>
                  <td>{image.status}</td>
                  <td>
                    <button onClick={() => handleSubmit(image.id, "approve")}>
                      Approve
                    </button>
                    <button onClick={() => handleSubmit(image.id, "reject")}>
                      Reject
                    </button>
                    <button
                      onClick={() => handleDeleteImage(image.id, fieldNameId)}
                      style={{
                        border: "none",
                        background: "none",
                        color: "red",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default FetchImages;
