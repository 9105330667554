import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import FieldInformation from "./FieldInformation"; // component for screen 0
import FieldDetails from "./FieldDetails";
import PumpingRecords from "./PumpingRecords";
import RainfallRecords from "./RailFallRecords";
import DryDownRecords from "./DryDownRecords";
import FieldRecordReview from "./FieldRecordReview";
import SubmittingModal from "../../Utils/SubmittingModal";
import AdminLayout from "../../components/AdminLayout";
import { isSuperAdmin } from "../../services/auth";

function SingleFieldRecord() {
  const { fieldNameId, fieldName } = useParams();
  const navigate = useNavigate();
  const decodedFieldName = decodeURIComponent(fieldName); // Decode the field name
  console.log("Field Name ID:", fieldNameId); // Logging to verify
  console.log("Field Name:", decodedFieldName); // Logging to verify

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const LayoutComponent = isSuperAdmin() ? AdminLayout : Layout;

  const [currentScreen, setCurrentScreen] = useState(0);
  const [fieldData, setFieldData] = useState("");
  const [datePermanentFlood, setDatePermanentFlood] = useState("");
  const [plantDate, setPlantDate] = useState("");
  const [variety, setVariety] = useState("");
  const [plantingMethod, setPlantingMethod] = useState("");

  // State to hold pumping records
  const [pumpingRecords, setPumpingRecords] = useState([
    { startDate: "", endDate: "" }, // Initialize with an empty record or as needed
  ]);

  // State to hold rainfall records
  const [rainfallRecords, setRainfallRecords] = useState([
    // Initialize with sample or empty records as needed
    { date: "", amount: "" },
  ]);

  // State to hold dry down records
  const [dryDownRecords, setDryDownRecords] = useState([
    // Initialize with an empty record or as needed
    { beginDate: "", endDate: "", level: "" },
  ]);

  const [isLoading, setIsLoading] = useState(false); // Loading state for field data
  const [isSubmitting, setIsSubmitting] = useState(false); // Loading state for form submission

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Navigate to the next screen
  const goToNextScreen = () => {
    if (currentScreen <= 5) {
      // Ensure we have only 5 screens (0-4)
      setCurrentScreen(currentScreen + 1);
    }
  };

  // Navigate to the previous screen
  const goToPreviousScreen = () => {
    if (currentScreen > 0) {
      setCurrentScreen(currentScreen - 1);
    }
  };

  useEffect(() => {
    // Simulating an API call to fetch field data
    const fetchFieldData = async () => {
      setIsLoading(true);
      try {
        setFieldData(decodedFieldName); // Simulated API response
      } catch (error) {
        console.error("Error fetching field data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFieldData();
  }, [decodedFieldName]);

  // Update the datePermanentFlood state based on user input
  const handleDateChange = (newDate) => {
    setDatePermanentFlood(newDate);
  };

  console.log("fieldData", fieldData);
  console.log("datePermanentFlood", datePermanentFlood);

  const submitAllRecords = async () => {
    const confirmSubmit = window.confirm(
      "Are you sure you want to submit the records? Make sure you have entered the full and correct data. You will not be able to make changes to your records and form data will get reset after submission."
    );
    if (!confirmSubmit) {
      setIsSubmitting(false);
      return; // If the user clicks "Cancel," return early and do not proceed with submission
    }

    setIsSubmitting(true); // Start the submission process

    // Consolidate all form data into one object
    const allRecordsData = {
      fieldDetails: {
        floodDateEstablished: datePermanentFlood,
        plantDate: plantDate,
        variety: variety,
        plantingMethod: plantingMethod,
      },
      pumpingRecords: pumpingRecords.map((record) => ({
        startDate: record.startDate,
        endDate: record.endDate,
      })),
      rainfallRecords: rainfallRecords.map((record) => ({
        dateOfRainfall: record.date,
        amountIn: record.amount,
      })),
      dryDownRecords: dryDownRecords.map((record) => ({
        beginDate: record.beginDate,
        endDate: record.endDate,
        levelIn: record.level,
      })),
      fieldNameId: parseInt(fieldNameId),
    };

    try {
      // Make a single API call to submit all records
      const response = await fetch(`${API_BASE_URL}/api/submitAllRecords`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(allRecordsData),
      });

      // Check for specific response statuses
      if (!response.ok) {
        const errorMessage = await response.text();
        if (response.status === 404) {
          throw new Error("Field name ID not found.");
        } else if (response.status === 409) {
          throw new Error(
            "Field records are already submitted for this field name. You can't resubmit."
          );
        } else {
          throw new Error(errorMessage || "Failed to submit records.");
        }
      }

      alert("All records submitted successfully!");
      navigate("/FieldDashboard", { state: { submittedFieldId: fieldNameId } }); // Redirect to FieldDashboard after successful submission
    } catch (error) {
      console.error("Error submitting records:", error);
      alert(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Render the current screen based on the currentScreen state
  const renderScreen = () => {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    switch (currentScreen) {
      case 0:
        return (
          <FieldInformation
            fieldName={decodedFieldName}
            fieldData={fieldData}
            datePermanentFlood={datePermanentFlood}
            onDateChange={handleDateChange}
            onNext={goToNextScreen}
          />
        );
      case 1:
        return (
          <FieldDetails
            onNext={goToNextScreen}
            onPrevious={goToPreviousScreen}
            plantDate={plantDate}
            setPlantDate={setPlantDate}
            variety={variety}
            setVariety={setVariety}
            plantingMethod={plantingMethod}
            setPlantingMethod={setPlantingMethod}
          />
        );
      case 2:
        return (
          <PumpingRecords
            onNext={goToNextScreen}
            pumpingRecords={pumpingRecords}
            setPumpingRecords={setPumpingRecords}
            onPrevious={goToPreviousScreen}
          />
        );
      case 3:
        return (
          <RainfallRecords
            rainfallRecords={rainfallRecords}
            setRainfallRecords={setRainfallRecords}
            onNext={goToNextScreen}
            onPrevious={goToPreviousScreen}
          />
        );
      case 4:
        return (
          <DryDownRecords
            dryDownRecords={dryDownRecords}
            setDryDownRecords={setDryDownRecords}
            onNext={goToNextScreen}
            onPrevious={goToPreviousScreen}
          />
        );
      case 5:
        return (
          <FieldRecordReview
            formData={{
              fieldData,
              datePermanentFlood,
              plantDate,
              variety,
              plantingMethod,
            }}
            pumpingRecords={pumpingRecords}
            rainfallRecords={rainfallRecords}
            dryDownRecords={dryDownRecords}
            onPrevious={goToPreviousScreen}
            onSubmit={submitAllRecords}
            isSubmitting={isSubmitting}
          />
        );
      default:
        return null; // Fallback for an undefined screen
    }
  };

  return (
    <LayoutComponent>
      <div className="Field-Container">{renderScreen()}</div>
      <SubmittingModal show={isSubmitting} /> {/* Display the modal */}
    </LayoutComponent>
  );
}

export default React.memo(SingleFieldRecord);
