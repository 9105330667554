import React, { useState } from "react";
import { Link } from "react-router-dom";

const ImageUpload = ({ fieldId, farmerId, applicationId, onUploadSuccess }) => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // Check if the file is an image
      if (file.type.startsWith("image/")) {
        // Check if the file size is less than or equal to 5MB
        if (file.size <= 5242880) {
          // 5MB in bytes
          setSelectedFile(file);
          setError(""); // Clear any previous errors
        } else {
          setError("File size must not exceed 5MB");
          setSelectedFile(null); // Clear any previous file selection
        }
      } else {
        setError("Please upload a valid image file");
        setSelectedFile(null); // Clear any previous file selection
      }
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      console.error("No file selected or file type/size is invalid.");
      return; // Exit if no file is selected or if there's a file type/size error
    }

    const formData = new FormData();
    formData.append("farmerId", farmerId); // Including the farmerId in the form data
    formData.append("applicationId", applicationId); // Including the applicationId in the form data
    formData.append("fieldId", fieldId); // Including the fieldId in the form data
    formData.append("image", selectedFile);

    console.log("Uploading image...", formData);

    const uploadUrl = `${API_BASE_URL}/api/images/upload`;

    try {
      const response = await fetch(uploadUrl, {
        method: "POST",
        body: formData,
        // Include headers as needed, for example, authorization headers
      });

      if (response.ok) {
        console.log("Upload successful");
        onUploadSuccess(fieldId); // Call a function to handle successful upload
        setError(""); // Clear any error messages
        alert("Image uploaded successfully");
      } else {
        console.error("Upload failed");
        setError("Failed to upload image.");
      }
    } catch (error) {
      console.error("Error during upload:", error);
      setError("An error occurred during upload.");
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} accept="image/*" />
      {error && <p style={{ color: "red" }}>{error}</p>}
      <button onClick={handleUpload} disabled={!selectedFile}>
        Upload Image
      </button>
    </div>
  );
};

export default ImageUpload;
