import React from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const FieldRecordReview = ({
  formData,
  pumpingRecords,
  rainfallRecords,
  dryDownRecords,
  onPrevious,
  onSubmit,
}) => {
  const handleDownload = () => {
    const doc = new jsPDF();

    // Add title
    doc.setFontSize(14);
    doc.text("Field Records Page", 105, 15, null, null, "center");

    // Field Details Section
    doc.setFontSize(10);
    doc.text(`Time of submission: ${new Date().toLocaleTimeString()}`, 20, 20);
    doc.text(`Date of submission: ${new Date().toLocaleDateString()}`, 20, 25);
    doc.text(`Field Name: ${formData.fieldData}`, 20, 30);
    doc.text(
      `Date Permanent Flood Established: ${formData.datePermanentFlood}`,
      20,
      37
    );
    doc.text(`Plant Date: ${formData.plantDate}`, 20, 44);
    doc.text(`Variety: ${formData.variety}`, 20, 51);
    doc.text(`Planting Method: ${formData.plantingMethod}`, 20, 58);

    // Pumping Records Section
    doc.setFontSize(12);
    doc.text("Pumping Records", 20, 70);

    doc.autoTable({
      startY: 75,
      head: [["Pumping Start Date", "Pumping End Date"]],
      body: pumpingRecords.map((record) => [record.startDate, record.endDate]),
      styles: { fontSize: 10 },
    });

    // Rainfall Records Section
    doc.setFontSize(12);
    doc.text("Rainfall Records", 20, doc.autoTable.previous.finalY + 10);

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 15,
      head: [["Date of Rainfall", "Amount (in)"]],
      body: rainfallRecords.map((record) => [record.date, record.amount]),
      styles: { fontSize: 10 },
    });

    // Dry Down Records Section
    doc.setFontSize(12);
    doc.text("Dry Down Records", 20, doc.autoTable.previous.finalY + 10);

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 15,
      head: [
        ["Dry Down Begin Date", "Dry Down End Date", "Dry Down Level (in)"],
      ],
      body: dryDownRecords.map((record) => [
        record.beginDate,
        record.endDate,
        record.level,
      ]),
      styles: { fontSize: 10 },
    });

    // Save the PDF
    doc.save("FieldRecordSummary.pdf");
  };

  return (
    <div>
      <h2>Review of Field Records</h2>
      {/* Display form data summary */}
      <h4>Date of submission: {new Date().toLocaleTimeString}</h4>
      <div>
        {Object.entries(formData).map(([key, value]) => (
          <p key={key}>{`${key}: ${value}`}</p>
        ))}
      </div>

      {/* Display Pumping Records Summary */}
      {pumpingRecords && pumpingRecords.length > 0 && (
        <div>
          <h3>Pumping Records</h3>
          {pumpingRecords.map((record, index) => (
            <p key={index}>
              Start: {record.startDate}, End: {record.endDate}
            </p>
          ))}
        </div>
      )}

      {/* Display Rainfall Records Summary */}
      {rainfallRecords && rainfallRecords.length > 0 && (
        <div>
          <h3>Rainfall Records</h3>
          {rainfallRecords.map((record, index) => (
            <p key={index}>
              Date: {record.date}, Amount: {record.amount} inches
            </p>
          ))}
        </div>
      )}

      {/* Display Dry Down Records Summary */}
      {dryDownRecords && dryDownRecords.length > 0 && (
        <div>
          <h3>Dry Down Records</h3>
          {dryDownRecords.map((record, index) => (
            <p key={index}>
              Begin Date: {record.beginDate}, End Date: {record.endDate}, Level:{" "}
              {record.level}
            </p>
          ))}
        </div>
      )}

      <button onClick={onPrevious}>Previous</button>
      <button onClick={handleDownload}>Download PDF</button>
      <button onClick={onSubmit}>Submit</button>
    </div>
  );
};

export default FieldRecordReview;
